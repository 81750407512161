import React, { Component } from 'react';
import Cookies from 'universal-cookie';
export const LanguageContext = React.createContext();
export class LanguageProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: {
                en: {
                    onlineorder: 'Online Order',
                    cartupdatesuccess: 'Cart Updated successfully',
                    discount: 'Discount',
                    typepromocode: 'Please Type Promo Code',
                    choosecountry: 'choose country',
                    country: 'Country',
                    erroraddorder: 'Error In Adding Order',
                    orderaddedsuccess: 'Order is added successfully',
                    completemissingfields: 'Please Complete the missing fields',
                    passwordsdontmatch: "Passwords don't match",
                    errorcreateaccount: 'Error In Creating Your Account',
                    errorupdateaccount: 'Error In Updating Your Account',
                    successcreateaccount: 'Your Account Has Been Created Successfully',
                    successupdateaccount: 'Your Account Has Been Updated Successfully',
                    cartempty: 'Cart is empty!',
                    gotomenu: 'Go to Menu',
                    branches: 'Branches',
                    choosebranch: 'choose branch',
                    errorsendmessage: 'Error in sending message',
                    successsendmessage: 'Message sent successfully',
                    choosecountryfirst: 'choose a country first',
                    choosestatefirst: 'choose a state first',
                    promocodeapplied: 'PromoCode Applied',
                    promocoderemoved: 'PromoCode Removed',
                    infoupdatesuccess: 'Information Updated Successfully',
                    ordersuccess: 'Order Success',

                    //About
                    our: 'Our',
                    story: 'Story',
                    description:
                        'The history of Zia Amelia begins with its opening in December 2012 in Downtown El Gouna, crowning a dream of its founders, Riccardo and Patrizia. Since the beginning, Zia Amelia has been characterized by the careful search for refined taste in the culinary proposal that, wisely, combines the flavors of tradition and innovation of Italian cuisine for the most demanding palettes. Even the choice of furniture which is placed as an essential element of the entire Zia Amelia experience that works to serve our main objective that is giving every customer an unforgettable, purely Italian experience',
                    founder: 'Founder',
                    italian: 'Italian',
                    cuisine: 'cuisine',
                    useof: ' Use of',
                    tomatoes: 'Tomatoes',
                    lovefor: 'Love for',
                    cheese: 'Cheese',
                    herbsand: 'Herbs and',
                    spices: 'Spices',
                    family: 'Family',

                    //checkout
                    checkout: 'Checkout',
                    name: 'Name',
                    phonenumber: 'Phone Number',
                    youremail: 'Your Email',
                    state: 'State',
                    city: 'City',
                    youraddress: 'Your Address',
                    paymentmethod: 'Payment Method',
                    cashondelivery: 'Cash On Delivery',
                    onlinepayment: 'Online Payment',
                    items: 'Items',
                    doyouhaveapromocode: 'Do you have promo code?',
                    subtotal: 'Sub Total',
                    shippingfees: 'Shipping Fees',
                    total: 'Total',
                    apply: 'Apply',
                    confirmorder: 'Comfirm Order',
                    choosestate: 'choose state',
                    choosecity: 'choose city',
                    outofzone: 'Out of Zone',
                    estimatedarrivaltime: 'Estimated Arrival Time',

                    //contactus
                    getin: 'Get in',
                    touch: 'Touch',
                    youcan: 'You can',
                    findusat: 'find us at',
                    downtownelgouna: 'Downtown, El Gouna',
                    serving: 'Serving',
                    hours: 'Hours',
                    leavea: 'Leave a',
                    message: 'Message',
                    haveaquestion: 'Have a question?',
                    donthesitatetocontactus: "Don't hesitate to contact us",
                    sendyourmessage: 'Send Your Message',
                    yourmessage: 'Your Message',
                    mobilenumber: 'Mobile Number',
                    yourname: 'Your Name',

                    //footer
                    ourlocation: 'Our Location',
                    followuson: 'Follow us on',

                    //gallery
                    gallery: 'Gallery',

                    //home
                    thestory: 'The Story',
                    behindus: 'behind Us',
                    ourdaily: 'Our daily',
                    specials: 'Specials',
                    filetto: 'Filetto Di Manzo',

                    homedescription: "One of the most beautiful things in life is the way we have to regularly stop everything we're doing and devote our attention to food Di Manzo",

                    //login
                    welcome: 'Welcome',
                    back: 'Back',
                    donthaveanaccount: "Don't have an account?",
                    signupnow: 'Sign up now!',
                    password: 'Password',
                    forgotpassword: 'Forgot your password?',
                    login: 'Login',
                    logout: 'Logout',

                    loggedoutsuccess: 'Logged out successfully',
                    logouterror: 'Error Logging Out',
                    loginsuccess: 'Login Success',

                    //menu
                    placeanorder: 'Place an order',
                    chooseyour: 'Choose your',
                    resturant: 'restaurant',
                    viewmenu: 'View Menu',
                    selectthebranch: 'Select the branch',
                    selectthewaytorecievethefood: 'Select the way to receive the food',
                    selectyourmenupreference: 'Select your menu preference',
                    gouna: 'Gouna',
                    newcairo: 'New Cairo',
                    dine: 'Dine',
                    online: 'Online',
                    deliciousdishes: 'Delicious Dishes',
                    select: 'Select',
                    nodishes: 'No Dishes',

                    //place an order
                    addtocart: 'ADD TO CART',
                    category: 'CATEGORY',
                    Description: 'Description',
                    ingredients: 'Ingredients',
                    relatedproducts: 'Related Products',
                    addedtocartsuccess: 'Cart is updated successfully',
                    carterror: 'Error In Updating Cart',

                    //pervious orders
                    previousorders: 'Previous Orders',
                    orderid: 'Order ID',
                    status: 'Status',
                    quantity: 'Quantity',
                    view: 'View',
                    shippingaddress: 'Shipping Address',
                    address: 'Address',
                    orderinvoice: 'Order Invoice',
                    orderdetails: 'Order Details',
                    update: 'Update',

                    //signup
                    create: 'Create',
                    account: 'Account',
                    alreadyhaveanaccount: 'Already have an account?',
                    firstname: 'First Name',
                    lastname: 'Last Name',
                    confirmpassword: 'Confirm Password',
                    signup: 'Sign up',
                    accountinfo: 'Account informaction',

                    //viewcart
                    mycart: 'My Cart',
                    productname: 'Product Name',
                    price: 'Price',
                    remove: 'Remove',

                    //header
                    menu: 'Menu',
                    about: 'About',
                    contact: 'Contact',
                    cart: 'Cart',
                    english: 'English',
                    viewcart: 'View Cart',

                    //forgetpassword
                    forgot: 'Forgot',
                    sendcode: 'Send Code',
                    vcode: 'Verfication Code',
                    verify: 'Verify',
                    submit: 'Submit',

                    //reservations
                    iwanttoreserve: 'I want to reserve',
                    atablefor: 'a tabe for',
                    persons: 'persons',
                    yourcomment: 'Your Comment',
                    sendyourrequest: 'Send Your Request',
                    reservationtime: 'Reservation Time',
                    diningarea: 'Dining Area',
                    choosediningarea: 'choose dining area',
                    outdoor: 'Outdoor',
                    indoor: 'Indoor',
                    pleasewriteavalidname: 'Please write a valid name',
                    pleasewriteavalidphonenumber: 'Please write a valid phone number',
                    pleasewriteavalidemail: 'Please write a valid email',
                    errorinaddingreservation: 'Error In Adding Reservation',
                    tablesuccessfullyreservedreservationnumber: ' Table Successfully reserved, Reservation number',
                },
                ar: {},
            },
            langdetect: '',
            setlang: this.setlang,
        };
    }

    setlang = (key) => {
        const cookies = new Cookies();
        cookies.set('sitelang', key, { path: '/' });
        this.setState({
            langdetect: key,
        });
        window.location.reload();
    };

    componentDidMount() {
        const cookies = new Cookies();
        var lastlangselected = cookies.get('sitelang');
        if (lastlangselected == 'en') {
            this.setState({
                langdetect: 'en',
            });
            document.body.setAttribute('dir', 'ltr');
        } else if (lastlangselected == 'ar') {
            this.setState({
                langdetect: 'en',
            });
            document.body.setAttribute('dir', 'rtl');
        } else {
            this.setState({
                langdetect: 'en',
            });
            document.body.setAttribute('dir', 'ltr');
        }
    }

    render() {
        var setlang = this.state.setlang;
        var lang = '';

        if (this.state.langdetect == 'en') {
            lang = this.state.lang.en;
            document.body.setAttribute('dir', 'ltr');
        } else if (this.state.langdetect == 'ar') {
            lang = this.state.lang.ar;
            document.body.setAttribute('dir', 'rtl');
        } else {
            lang = this.state.lang.ar;
            document.body.setAttribute('dir', 'rtl');
        }

        var langdetect = this.state.langdetect;
        return <LanguageContext.Provider value={{ lang, setlang, langdetect }}>{this.props.children}</LanguageContext.Provider>;
    }
}

export const LanguageContextConsumer = LanguageContext.Consumer;
