import axios from 'axios';
import Cookies from 'universal-cookie';
import { serverbaselink } from '../../Env_Variables';
const axiosheaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
const getTempcustomercartinfo = () => {
    var crealdata = null;
    // const cookies = new Cookies();
    var cdata = localStorage.getItem('cdata');
    if (cdata != undefined) {
        crealdata = cdata;
    }
    return crealdata;
};
const getcurrencyid = () => {
    var currencyid = null;
    const cookies = new Cookies();

    var currencydata = cookies.get('currency');
    if (currencydata != undefined) {
        currencyid = currencydata;
    }
    return currencyid;
};
const getLang = () => {
    var currencyid = null;
    const cookies = new Cookies();

    var currencydata = cookies.get('sitelang');
    if (currencydata != undefined) {
        currencyid = currencydata;
    }
    return currencyid;
};
export const AddtoCart_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/addtocart',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};

export const fetchcollections_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/fetchcollections',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};
export const fetchparentcollections_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/fetchparentcollections',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};

export const fetchproducts_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/fetchproducts',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};
export const fetchProductInfo_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/fetchproductinfo',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};
export const fetchcustomercart_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/fetchcustomercart',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};

export const Login_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/login',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};
export const Logout_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/logout',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};

export const fetchAuthorization_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/fetchAuthorization',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};
export const ApplyPromoCode_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/applypromocode',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};

export const fetchTabexCountriesStates_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/fetchTabexCountriesStates',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};

export const AddOrder_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/AddOrderRouting',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};

export const fetchorderhistory_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/fetchorderhistory',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};

export const CustomerSignUp_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/signup',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};

export const Contactus_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/sendcontactusform',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};
export const ChooseState_Country_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/ChooseState_Country',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};
export const addReservation_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/addreservation',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};
export const fetchBranches_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/fetchbranches',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};
export const Forgotpassword_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/forgetpassword',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};

export const Fetchgallery_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    // axiosdatatemp.instapikey = instapikey();
    axiosdatatemp.cdata = getTempcustomercartinfo();
    axiosdatatemp.currencyid = getcurrencyid();
    axiosdatatemp.lang = getLang();

    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/customer/webapp/fetchgallery',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};
