import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
export const Contexthandlerscontext = React.createContext();

export const Contexthandlerscontext_provider = (props) => {
    let history = useHistory();
    const { lang, langdetect } = useContext(LanguageContext);
    const [pagesarray_context, setpagesarray_context] = useState([
        {
            pagename: 'Menu',
            isselected: false,
            reactpath: '/choosebranch/menu',
        },
        {
            pagename: 'Reservations',
            isselected: false,
            reactpath: '/choosebranch/reservations',
        },
        {
            pagename: 'Gallery',
            isselected: false,
            reactpath: '/gallery/0',
        },
        {
            pagename: 'About',
            isselected: false,
            reactpath: '/about/sectionone',
        },
        {
            pagename: 'Contact',
            isselected: false,
            reactpath: '/contactus',
        },
    ]);
    const [pagetitle_context, setpagetitle_context] = useState('');
    const setpageactive_context = (route) => {
        var temparr = [...pagesarray_context];
        temparr.forEach((sideelement, index) => {
            if (sideelement.reactpath == route) {
                sideelement['isselected'] = true;
            } else {
                sideelement['isselected'] = false;
            }
        });
        setpagesarray_context([...temparr]);
    };
    return (
        <Contexthandlerscontext.Provider
            value={{
                pagesarray_context,
                setpagesarray_context,
                pagetitle_context,
                setpagetitle_context,
                setpageactive_context,
            }}
        >
            {props.children}
        </Contexthandlerscontext.Provider>
    );
};
