import React, { Component, useState } from 'react';
import { fetchAuthorization_API, fetchBranches_API } from './components/API/API';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';

export const Loggedincontext = React.createContext();

export const Loggedincontext_provider = (props) => {
    const fetchuseauthorizationQueryContext = useQuery(['fetchAuthorization_API'], () => fetchAuthorization_API(), { keepPreviousData: true, staleTime: 500000000000000000 });
    const fetchbranchesqueryContext = useQuery(['fetchBranches_API'], () => fetchBranches_API(), { keepPreviousData: true, staleTime: 500000000000000000 });

    const [isloggedincontext, setisloggedincontext] = useState(false);
    const [instbranchescontext, setinstbranchescontext] = useState([]);
    const [userloggedinfobjcontext, setuserloggedinfobjcontext] = useState({
        userinfo: {
            name: '',
            email: '',
            mobile: '',
            type: '',
            useractive: '',
            currentinstactive: '',
        },
        currentinstinfo: {
            instname: '',
            instlogo: '',
        },
    });

    return (
        <Loggedincontext.Provider
            value={{
                isloggedincontext,
                setisloggedincontext,
                userloggedinfobjcontext,
                setuserloggedinfobjcontext,
                instbranchescontext,
                setinstbranchescontext,
                fetchuseauthorizationQueryContext,
                fetchbranchesqueryContext,
            }}
        >
            {props.children}
        </Loggedincontext.Provider>
    );
};

export const Loggedincontext_consumer = Loggedincontext.Consumer;
